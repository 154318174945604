import { ImageGallery } from 'schema-dts'

import { EntityElastic } from '../../../types/entity'
import { CompleteStructuredDataParserProps, ProcessedGalleryStructuredData } from '../../../types/seo'
import truncate from '../../truncateString'
import { applyOverrideableSeoProperties } from '../applyOverrideValue'
import { completeAuthorStructuredData } from '../author'
import { ParsedBasicProperties } from '../basicSchemaProperties'
import { parseReferencableThing } from '../referenceThing'
import parseSeoUrl from '../url'
import completeImageGallery from './imageGallery'

export const completeGalleryStructuredData = ({
    item,
    imagesMeta,
    organizationSchema,
    asPath,
    override,
    basePath,
    websiteSchema,
    isMainEntity
}: CompleteStructuredDataParserProps): ImageGallery | null => {
    if (!item?.structuredData?.parsed) return null
    const { structuredData } = item
    const { parsed: parsedStructuredData, data: intermediateData } = structuredData as ProcessedGalleryStructuredData
    const { summary, mainTitle, author: authorData } = intermediateData
    const author = completeAuthorStructuredData(organizationSchema, authorData, basePath)
    const url = parseSeoUrl(item, asPath, basePath)
    const { title: name, description } = applyOverrideableSeoProperties(
        {
            title: mainTitle || parsedStructuredData?.name?.toString(),
            description: parsedStructuredData?.description?.toString()
        },
        override
    )
    // @NOTE: rest of the properties are appended once frontend values are known
    return {
        ...parsedStructuredData,
        description,
        name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'ImageGallery',
        ...(!summary && {
            author,
            thumbnailUrl: imagesMeta?.[0]?.url,
            // primaryImageOfPage: imagesMeta?.[0]?.url,
            mainEntityOfPage: isMainEntity ? completeImageGallery(imagesMeta || [], url) : undefined,
            publisher: parseReferencableThing(organizationSchema),
            headline: truncate(name, 110, '...'),
            isPartOf: parseReferencableThing(websiteSchema)
        })
    }
}

const parseGallery = (
    item: EntityElastic,
    basicProperties: ParsedBasicProperties,
    summary = false,
    bodyMaxWords = 100
): ProcessedGalleryStructuredData => {
    const { title, description, publishDate, updateDate, author } = basicProperties

    const data = { summary, bodyMaxWords, author, mainTitle: title }

    const parsed: ImageGallery = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'ImageGallery',
        ...(!summary && {
            description,
            inLanguage: 'Croatian',
            // thumbnailUrl: imageUrl,
            dateCreated: publishDate,
            datePublished: publishDate,
            dateModified: updateDate,
            about: description
            // primaryImageOfPage: imageUrl
        })
    }

    return {
        data,
        parsed
    }
}

export default parseGallery
