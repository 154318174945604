import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { VideoObject } from 'schema-dts'

import { EntityElastic } from '../../types/entity'
import { CompleteStructuredDataParserProps, ProcessedVideoStructuredData } from '../../types/seo'
// import stripHtml from '../stripHtmlTags'
import { applyOverrideableSeoProperties } from './applyOverrideValue'
import { completeAuthorStructuredData } from './author'
import { ParsedBasicProperties } from './basicSchemaProperties'
import { parseReferencableThing } from './referenceThing'
import parseSeoUrl from './url'

dayjs.extend(duration)

export const completeVideoStructuredData: (props: CompleteStructuredDataParserProps) => VideoObject | null = ({
    item,
    imagesMeta,
    organizationSchema,
    override,
    basePath,
    asPath,
    websiteSchema,
    isMainEntity
}) => {
    if (!item?.structuredData?.parsed) return null
    const { structuredData } = item
    const {
        parsed: { name: sdName, description: sdDescription, ...parsedStructuredData },
        data: intermediateData
    } = structuredData as ProcessedVideoStructuredData
    const { title: name, description } = applyOverrideableSeoProperties(
        { title: sdName?.toString(), description: sdDescription?.toString() },
        override
    )
    const { author: authorData, summary } = intermediateData
    const author = completeAuthorStructuredData(organizationSchema, authorData, basePath)
    const url = parseSeoUrl(item, asPath, basePath)
    // @NOTE: rest of the properties are appended once frontend values are known
    return {
        ...parsedStructuredData,
        image: imagesMeta?.[0]?.url,
        thumbnailUrl: imagesMeta?.[0]?.url,
        author,
        name,
        description,
        publisher: parseReferencableThing(organizationSchema),
        ...(!summary && {
            thumbnailUrl: imagesMeta?.[0]?.url,
            mainEntityOfPage: isMainEntity ? url : undefined,
            isPartOf: parseReferencableThing(websiteSchema)
        })
    }
}

const parseVideo = (
    item: EntityElastic,
    basicProperties: ParsedBasicProperties,
    summary = false,
    bodyMaxWords = 100
): ProcessedVideoStructuredData => {
    const { title, description, publishDate, updateDate, author } = basicProperties

    const data = { summary, bodyMaxWords, author }
    const videoDuration = item?.duration ? dayjs.duration({ seconds: item.duration }).toISOString() : undefined
    const parsed: VideoObject = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'VideoObject',
        name: title,
        description,
        uploadDate: publishDate,
        contentUrl: item?.original_url,
        ...(!summary && {
            inLanguage: 'Croatian',
            dateModified: updateDate,
            duration: videoDuration,
            author: author || undefined
        })
    }

    return {
        data,
        parsed
    }
}

export default parseVideo
