import deepmerge from 'deepmerge'
import { NextSeo } from 'next-seo'
import { useMemo } from 'react'
import { Organization, WebPage, WebSite, Thing} from 'schema-dts'

import { isThingReferencable } from '../../helpers/seo/referenceThing'
import { EntityElastic } from '../../types/entity'
import { SeoOverride } from '../../types/seo/SeoOverride'
import { StructuredData } from '../StructuredData'
import { useItemListStructuredData, useSeoData } from './hooks'
import { parseSeoDate } from '@hmn/rtl-web-core/helpers/seo'
import { DateOverride } from '@hmn/rtl-web-core/types/seo'

export interface SeoProps {
    item: any
    images: any[] // @TODO: specify entity types
    hasAmp?: boolean
    basePath?: string
    organization: Organization
    website: WebSite
    listItems: EntityElastic[]
    widgetListWithData?: any // @TODO: specify entity types
    defaultImage?: {
        width: number
        height: number
        url: string
    }
    override?: SeoOverride
    mainStructuredDataOverride?: WebPage
    minorItem?: boolean
    minorList?: boolean
    page: number
    noStructuredData?: boolean
    mainEntity?: boolean,
    nextSeoOverride?: Record<string,any>,
    dateFromFirstListItem?: boolean,
    modifiedDateFromFirstListItem?: boolean
    createdDateFromFirstListItem?: boolean
    publishedDateFromFirstListItem?: boolean
}

const LIST_IS_SUMMARY = true

export const Seo = ({
    item,
    images,
    basePath,
    organization,
    website,
    listItems,
    widgetListWithData,
    defaultImage,
    override,
    mainStructuredDataOverride,
    page,
    minorItem = false,
    minorList = false,
    noStructuredData = false,
    mainEntity = false,
    nextSeoOverride,
    modifiedDateFromFirstListItem = false,
    createdDateFromFirstListItem = false,
    publishedDateFromFirstListItem = false
}: SeoProps) => {
    const structuredItemList = useItemListStructuredData({
        mainItem: item,
        listItems,
        widgetListWithData,
        organizationSchema: organization,
        websiteSchema: website,
        page,
        enabled: (!minorItem || minorList) && !noStructuredData,
        summary: LIST_IS_SUMMARY
    })

    const dateOverride: Partial<DateOverride> | undefined = useMemo(()=>{
        const firstItemWithDate = listItems?.find(item => item?.published_at || item?.updated_at)
        if(!firstItemWithDate || !createdDateFromFirstListItem && !modifiedDateFromFirstListItem && !publishedDateFromFirstListItem) return
        const published = parseSeoDate(firstItemWithDate.published_at || firstItemWithDate.updated_at || firstItemWithDate.created_at)
        const created = parseSeoDate(firstItemWithDate.created_at || firstItemWithDate.published_at || firstItemWithDate.updated_at)
        const modified = parseSeoDate(firstItemWithDate.updated_at || firstItemWithDate.published_at || firstItemWithDate.created_at)
        return {
            created: createdDateFromFirstListItem && created || undefined,
            published: publishedDateFromFirstListItem && published || undefined,
            modified: modifiedDateFromFirstListItem && modified || undefined
        }

    },[listItems, createdDateFromFirstListItem, modifiedDateFromFirstListItem, publishedDateFromFirstListItem])
    
    const { metadata, mainStructuredData } = useSeoData({
        item,
        images,
        basePath,
        organization,
        website,
        listItems,
        page,
        defaultImage,
        override,
        minorItem,
        noStructuredData,
        isMainEntity: mainEntity,
        dateOverride
    })

    const mainItemStructuredData = useMemo(() => {
        if (!mainStructuredData) {
            if (mainStructuredDataOverride) return mainStructuredDataOverride
            return null
        }
        if (!mainStructuredDataOverride) {
            return mainStructuredData
        }
        return deepmerge(mainStructuredData as any, mainStructuredDataOverride as any) as WebPage
    }, [mainStructuredData, mainStructuredDataOverride])

    const structuredDataArray = useMemo(() => {
        const hasList = (!minorItem || minorList) && !!structuredItemList
        return [
            ...((!minorItem &&
                [isThingReferencable(organization) && organization, isThingReferencable(website) && website].filter(
                    Boolean
                )) ||
                []),
            mainItemStructuredData,
            ...((hasList && structuredItemList) || [])
        ]
    }, [mainItemStructuredData, structuredItemList, minorItem, minorList])

    return (
        <>
            {(!minorItem && !minorList && <>{metadata && <NextSeo {...metadata} {...(nextSeoOverride || {})} />}</>) || null}
            {(!noStructuredData && structuredDataArray.length && <StructuredData data={structuredDataArray} />) || null}
        </>
    )
}
export default Seo
